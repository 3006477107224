export const contentSizingOptions = [
	{
		id: "fill",
		label: "Fill",
		value: "fill",
	},
    {
		id: "hug",
		label: "Hug",
		value: "hug",
	},
	
];
