const Dark = {
	id: "DARK",
	name: "Dark",
	mode: "dark",
	tokens: {
		"--page-background-color": "rgba(0, 0, 0, 1)",
		"--heading-color": "rgba(255, 255, 255, 1)",
		"--body-color": "rgba(149, 149, 149, 1)",
		"--accent-color": "rgba(237, 0, 235, 1)",
		"--accent-color-40": "rgba(237, 0, 235, 0.4)",
		"--accent-color-10": "rgba(237, 0, 235, 0.1)",

		"--font-size": 17,
		"--heading-font": "ABCDiatypeVariable",
		"--heading-weight": 400,
		"--body-font": "ABCDiatypeVariable",
		"--body-weight": 400,
		"--light-mode-stroke": "var(--t0)",
		"--dark-mode-stroke": "var(--t5)",
	},
};

const Light = {
	id: "LIGHT",
	name: "Light",
	mode: "light",
	tokens: {
		"--page-background-color": "rgba(255, 255, 255, 1)",
		"--heading-color": "rgba(0, 0, 0, 1)",
		"--body-color": "rgba(149, 149, 149, 1)",
		"--accent-color": "rgba(237, 0, 235, 1)",
		"--accent-color-40": "rgba(237, 0, 235, 0.4)",
		"--accent-color-10": "rgba(237, 0, 235, 0.1)",

		"--font-size": 17,
		"--heading-font": "ABCSyntVariableVF-Trial",
		"--heading-weight": 500,
		"--body-font": "ABCDiatypeVariable",
		"--body-weight": 400,
		"--light-mode-stroke": "var(--t4)",
		"--dark-mode-stroke": "var(--t0)",
	},
};

const Creme = {
	id: "Creme",
	name: "Creme",
	mode: "light",
	tokens: {
		"--page-background-color": "rgba(239, 235, 229, 1.0)",
		"--heading-color": "rgba(72, 66, 64, 1.0)",
		"--body-color": "rgba(119, 113, 100, 1.0)",
		"--accent-color": "rgba(72, 66, 64, 1.0)",
		"--accent-color-40": "rgba(72, 66, 64, 0.4)",
		"--accent-color-10": "rgba(72, 66, 64, 0.1)",

		"--font-size": 17,
		"--heading-font": "ABCSyntVariableVF-Trial",
		"--heading-weight": 500,
		"--body-font": "ABCSyntVariableVF-Trial",
		"--body-weight": 400,
	},
};

const Ocean = {
	id: "Ocean",
	name: "Ocean",
	mode: "dark",
	tokens: {
		"--page-background-color": "rgba(70, 67, 231, 1.0)",
		"--heading-color": "rgba(255, 215, 158, 1.0)",
		"--body-color": "rgba(191, 232, 255, 1.0)",
		"--accent-color": "rgba(255, 215, 158, 1.0)",
		"--accent-color-40": "rgba(255, 215, 158, 0.4)",
		"--accent-color-10": "rgba(255, 215, 158, 0.1)",

		"--font-size": 17,
		"--heading-font": "ABCDiatypeVariable",
		"--heading-weight": 500,
		"--body-font": "ABCDiatypeVariable",
		"--body-weight": 400,
	},
};

const StychA = {
	id: "StychA",
	name: "Stych A",
	mode: "dark",
	tokens: {
		"--page-background-color": "rgba(210, 253, 226, 1.0)",
		"--heading-color": "rgba(30, 47, 60, 1.0)",
		"--body-color": "rgba(30, 47, 60, 1.0)",
		"--accent-color": "rgba(30, 47, 60, 1.0)",
		"--accent-color-40": "rgba(30, 47, 60, .4)",
		"--accent-color-10": "rgba(30, 47, 60, .1)",

		"--font-size": 17,
		"--heading-font": "IBM Plex Sans",
		"--heading-weight": 600,
		"--body-font": "IBM Plex Sans",
		"--body-weight": 400,
	},
};

const StychB = {
	id: "StychB",
	name: "Stych B",
	mode: "light",
	tokens: {
		"--page-background-color": "rgba(245, 252, 249, 1.0)",
		"--heading-color": "rgba(0, 0, 0, 1.0)",
		"--body-color": "rgba(30, 47, 60, 1.0)",

		"--accent-color": "rgba(0, 0, 0, 1.0)",
		"--accent-color-40": "rgba(0, 0, 0, 0.4)",
		"--accent-color-10": "rgba(0, 0, 0, 0.1)",

		"--font-size": 17,
		"--heading-font": "IBM Plex Sans",
		"--heading-weight": 600,
		"--body-font": "IBM Plex Sans",
		"--body-weight": 400,
	},
};

const StychC = {
	id: "StychC",
	name: "Stych C",
	mode: "light",
	tokens: {
		"--page-background-color": "rgba(255, 255, 255, 1.0)",
		"--heading-color": "rgba(30, 47, 60, 1.0)",
		"--body-color": "rgba(30, 47, 60, 1.0)",

		"--accent-color": "rgba(30, 47, 60, 1.0)",
		"--accent-color-40": "rgba(30, 47, 60, 0.4)",
		"--accent-color-10": "rgba(30, 47, 60, 0.1)",

		"--font-size": 17,
		"--heading-font": "IBM Plex Sans",
		"--heading-weight": 400,
		"--body-font": "IBM Plex Sans",
		"--body-weight": 400,

		"--light-mode-stroke": "var(--t4)",
		"--dark-mode-stroke": "var(--t0)",
	},
};

const StychD = {
	id: "StychD",
	name: "Stych D",
	mode: "dark",
	tokens: {
		"--page-background-color": "rgba(40, 95, 102, 1.0)",
		"--heading-color": "rgba(255, 255, 255, 1.0)",
		"--body-color": "rgba(255, 255, 255, 1.0)",

		"--accent-color": "rgba(255, 255, 255, 1.0)",
		"--accent-color-40": "rgba(255, 255, 255, 0.4)",
		"--accent-color-10": "rgba(255, 255, 255, 0.1)",

		"--font-size": 17,
		"--heading-font": "IBM Plex Sans",
		"--heading-weight": 600,
		"--body-font": "IBM Plex Sans",
		"--body-weight": 400,
	},
};

const TE_EP_133 = {
	id: "TE_EP_133",
	name: "TE ep-133",
	mode: "light",
	tokens: {
		"--page-background-color": "rgba(229, 229, 229, 1)",
		"--heading-color": "rgba(255, 119, 70, 1)",
		"--body-color": "rgba(39, 39, 39, 1)",
		"--accent-color": "rgba(255, 119, 70, 1)",
		"--accent-color-40": "rgba(255, 119, 70, 0.4)",
		"--accent-color-10": "rgba(255, 119, 70, 0.1)",

		"--font-size": 17,
		"--heading-font": "ABCDiatypeVariable",
		"--heading-weight": 300,
		"--body-font": "ABCDiatypeVariable",
		"--body-weight": 300,
		"--light-mode-stroke": "var(--t4)",
		"--dark-mode-stroke": "var(--t0)",
	},
};

const RampA = {
	id: "RampA",
	name: "Ramp A",
	mode: "light",
	tokens: {
		"--page-background-color": "rgba(244, 243, 239, 1)",
		"--heading-color": "rgba(28, 27, 23, 1)",
		"--body-color": "rgba(28, 27, 23, 0.6)",

		"--accent-color": "rgba(30, 50, 45, 1)",
		"--accent-color-40": "rgba(30, 50, 45, 0.4)",
		"--accent-color-10": "rgba(30, 50, 45, 0.1)",

		"--font-size": 16,
		"--heading-font": "TWKLausanne",
		"--heading-weight": 550,
		"--body-font": "TWKLausanne",
		"--body-weight": 400,
		"--light-mode-stroke": "var(--t4)",
		"--dark-mode-stroke": "var(--t0)",
	},
};

const RampB = {
	id: "RampB",
	name: "Ramp B",
	mode: "dark",
	tokens: {
		//"--page-background-color": "rgba(108, 108, 95, 1)",
		"--page-background-color": "rgba(30, 50, 45, 1)",
		"--heading-color": "rgba(255, 255, 255, 1)",
		"--body-color": "rgba(244, 243, 239, 0.85)",

		"--accent-color": "rgba(231, 242, 86, 1)",
		"--accent-color-40": "rgba(231, 242, 86, 0.4)",
		"--accent-color-10": "rgba(231, 242, 86, 0.1)",

		"--font-size": 13,
		"--heading-font": "TWKLausanne",
		"--heading-weight": 550,
		"--body-font": "TWKLausanne",
		"--body-weight": 400,
		"--light-mode-stroke": "var(--t4)",
		"--dark-mode-stroke": "var(--t0)",
	},
};

export const Themes = {
	//Creme: Creme,
	//Ocean: Ocean,
	StychA: StychA,
	StychB: StychB,
	StychC: StychC,
	StychD: StychD,

	DARK: Dark,
	LIGHT: Light,

	TE_EP_133: TE_EP_133,

	RampA: RampA,
	RampB: RampB,
};
