export const alignXOptions = [
	{
		id: "start",
		label: "Start",
		value: "start",
        icon: "AlignLeft"
	},
    {
		id: "center",
		label: "Center",
		value: "center",
        icon: "AlignCenter",
	},
    {
		id: "end",
		label: "End",
		value: "end",
        icon: "AlignRight",
	},
    {
		id: "stretch",
		label: "stretch",
		value: "stretch",
        icon: "ArrowUpDown",
	},
	{
		id: "space-between",
		label: "space-between",
		value: "space-between",
        icon: "ArrowUpDown",
	},
];


export const alignYOptions = [
	{
		id: "start",
		label: "Start",
		value: "start",
        icon: "AlignTop"
	},
    {
		id: "center",
		label: "Center",
		value: "center",
        icon: "AlignMiddle",
	},
    {
		id: "end",
		label: "End",
		value: "end",
        icon: "AlignBottom",
	},
    {
		id: "stretch",
		label: "stretch",
		value: "stretch",
        icon: "ArrowUpDown",
	},
	{
		id: "space-between",
		label: "space-between",
		value: "space-between",
        icon: "ArrowUpDown",
	},
	
];
