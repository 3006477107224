import React from "react";
import { motion } from "framer-motion";

const IconMap = {
	Ellipse: Ellipse,
	Rectangle: Rectangle,
	Text: Text,
	Line: Line,
};

export const DiagramPanelIcon = props => {
	const { name = "Circle", width = 96, height = 96, color = "rgba(255,255,255,1.0)" } = props;
	const Drawing = IconMap[name] ? IconMap[name] : null;
	if (!Drawing) console.log("Missing Icon: " + name);
	return (
		<motion.svg
			width={width}
			height={height}
			viewBox={`0 0 96 96`}
			fill="none"
			style={{
				display: "block",
				position: "relative",
				pointerEvents: "none",
				flexShrink: 0,
				overflow: "hidden",
			}}
			animate={{
				scale:props.scale,
				fill: props.color
			}}
			initial={false}
		>
			<Drawing color={color} />
		</motion.svg>
	);
};

function Ellipse(props) {
	return <circle cx="48" cy="48" r="48" fill={props.color} />;
}

function Rectangle(props) {
	return <rect x="0" y="0" width="96" height="96" rx="0" fill={props.color} />;
}

function Text(props) {
	return (
		<g>
			<path
				d="M28.3568 55.08V36.1104H21V33.8H38.4496V36.1104H31.1232V55.08H28.3568Z"
			
			/>
			<path
				d="M43.8128 55.536C42.3536 55.536 41.087 55.2421 40.0128 54.6544C38.959 54.0464 38.1382 53.1547 37.5504 51.9792C36.983 50.8037 36.6992 49.3749 36.6992 47.6928C36.6992 46.1931 37.0032 44.8656 37.6112 43.7104C38.2192 42.5349 39.04 41.6128 40.0736 40.944C41.1275 40.2752 42.3232 39.9408 43.6608 39.9408C45.12 39.9408 46.3563 40.2651 47.3696 40.9136C48.383 41.5419 49.143 42.4437 49.6496 43.6192C50.1766 44.7744 50.44 46.1424 50.44 47.7232V48.24H39.3136C39.3339 49.3547 39.5467 50.3072 39.952 51.0976C40.3574 51.8677 40.8944 52.4656 41.5632 52.8912C42.2523 53.2965 43.0123 53.4992 43.8432 53.4992C44.735 53.4992 45.5558 53.2864 46.3056 52.8608C47.0555 52.4352 47.5216 51.6651 47.704 50.5504H50.288C50.1867 51.4016 49.8827 52.2123 49.376 52.9824C48.8896 53.7323 48.1904 54.3504 47.2784 54.8368C46.3664 55.3029 45.2112 55.536 43.8128 55.536ZM39.4048 46.3856H47.6432C47.6432 45.0683 47.2987 44.0144 46.6096 43.224C45.9206 42.4133 44.9376 42.008 43.6608 42.008C42.8502 42.008 42.1307 42.2005 41.5024 42.5856C40.8944 42.9707 40.408 43.4976 40.0432 44.1664C39.6784 44.8149 39.4656 45.5547 39.4048 46.3856Z"
			
			/>
			<path
				d="M51.7004 55.08L57.2028 47.5712L52.0044 40.4272H55.1964L58.054 44.6528C58.1959 44.8555 58.3276 45.048 58.4492 45.2304C58.5911 45.4128 58.7228 45.5952 58.8444 45.7776C58.9458 45.6155 59.0572 45.4432 59.1788 45.2608C59.3004 45.0784 59.4322 44.8859 59.574 44.6832L62.4924 40.4272H65.5324L60.2124 47.5712L65.7452 55.08H62.5836L59.4828 50.5504C59.3612 50.3477 59.2295 50.1552 59.0876 49.9728C58.966 49.7701 58.8242 49.5675 58.662 49.3648C58.5202 49.5675 58.3783 49.7701 58.2364 49.9728C58.1148 50.1552 57.9932 50.3477 57.8716 50.5504L54.7404 55.08H51.7004Z"
			
			/>
			<path
				d="M73.626 55.3232C72.8964 55.3232 72.2174 55.1915 71.5892 54.928C70.9609 54.6645 70.4441 54.2187 70.0388 53.5904C69.6537 52.9419 69.4612 52.0805 69.4612 51.0064V42.5856H66.7252V40.4272H69.4612V35.5936H72.0756V40.4272H75.1156V42.5856H72.0756V50.7328C72.0756 51.584 72.2681 52.1717 72.6532 52.496C73.0585 52.8203 73.5854 52.9824 74.234 52.9824C74.4164 52.9824 74.5886 52.9723 74.7508 52.952C74.9332 52.9317 75.1257 52.9115 75.3284 52.8912V55.1408C75.0649 55.2016 74.7913 55.2421 74.5076 55.2624C74.2238 55.3029 73.93 55.3232 73.626 55.3232Z"
			
			/>
		</g>
	);
}



function Line(props) {
	return (
		<path
			fillRule="evenodd"
			clipRule="evenodd"
		
			d="M-3.99647e-07 48C-1.78928e-07 46.8954 0.89543 46 2 46L98 46C99.1046 46 100 46.8954 100 48C100 49.1046 99.1046 50 98 50L2 50C0.89543 50 -6.20366e-07 49.1046 -3.99647e-07 48Z"
		/>
	);
}
